import Anchor from '@oneflare/flarekit/lib/components/Anchor';
import CollapsibleContainer from '@oneflare/flarekit/lib/components/CollapsibleContainer';
import H4 from '@oneflare/flarekit/lib/components/H4';
import DownwardChevron from '@oneflare/flarekit/lib/components/icons/DownwardChevron';
import WeddingLogo from '@oneflare/flarekit/lib/components/logos/Wedding';
import media from '@oneflare/flarekit/lib/libs/media';
import { styled, css } from 'styled-components';

export const FooterWeddingLogo = styled(WeddingLogo).attrs(({ theme }) => ({
  textFill: theme.color.white,
  iconFill: theme.color.white,
  width: '230px'
}))``;

export const AccordionChevron = styled(DownwardChevron).attrs(({ theme }) => ({
  kind: 'hollow',
  fill: theme.color.white
}))<{
  $activeSection?: string;
  $headerName?: string;
}>`
  float: right;
  height: 16px;
  margin-top: 2px;
  width: 16px;
  transition: transform .4s linear;
  display: block;

  ${({ $activeSection, $headerName }) => {
    if ($activeSection === $headerName) {
      return css`
          margin-top: 9px;
          transform: rotate(180deg);
        `;
    }
    return null;
  }}

  ${media.mdUp`
    display: none;
  `}
`;

export const FooterWrapper = styled.footer`
  background-color: ${({ theme }) => theme.color.tertiary};
  display: grid;
  justify-content: center;
  grid-template-columns: minmax(auto, 1140px);
  padding: 40px 20px;

  ${media.mdUp`
    padding: 60px 40px;
  `}

  ${media.lgUp`
    padding: 80px 0;
  `}
`;

export const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0 0 32px;
  color: ${({ theme }) => theme.color.white};

  &:last-child {
    margin-bottom: 32px;
  }

  ${media.lgUp`
    flex: 1 0 0;
    margin: 0;
  `}

  &:hover {
    cursor: pointer;
  }
`;

export const ListItem = styled.li`
  margin-top: 13px;
`;

export const FooterBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${media.lgUp`
    flex-direction: row;
    margin: 48px 0 0;
  `}
`;

export const FooterHead = styled.div`
  margin-bottom: 32px;

  ${media.lgUp`
    margin: 0;
  `}
`;

export const FooterSubHeading = styled(H4)`
  margin: 0;
  font-family: ${({ theme }) => theme.font.secondaryBold};
  color: ${({ theme }) => theme.color.white};
`;

export const FooterAnchor = styled(Anchor)`
  font-family: ${({ theme }) => 'primary' in theme.font && theme.font.primary as string};
  font-size: 14px;
  margin: 16px 0 0;
  color: ${({ theme }) => theme.color.white};

  &:hover {
    color: ${({ theme }) => theme.color.white};
    text-decoration: underline;
  }
`;

export const CollapsibleItems = styled(CollapsibleContainer)`
  display: block;

  ${media.mdUp`
    display: none;
  `}
`;

export const ListItemContainer = styled.section`
  display: none;

  ${media.mdUp`
    display: block;
  `}
`;
