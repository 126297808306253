import H4 from '@oneflare/flarekit/lib/components/H4';
import DirectoryCheckList from '@oneflare/flarekit/lib/components/icons/DirectoryCheckList';
import GuaranteeBadge from '@oneflare/flarekit/lib/components/icons/GuaranteeBadge';
import ThumbsUp from '@oneflare/flarekit/lib/components/icons/ThumbsUp';
import Paragraph from '@oneflare/flarekit/lib/components/Paragraph';
import media from '@oneflare/flarekit/lib/libs/media';
import { styled } from 'styled-components';

export const StatsSectionStyled = styled.section`
  background-color: ${({ theme }) => theme.color.white};
  margin: 40px 20px;
  text-align: center;
  display: flex;
  flex-direction: column;

  ${media.mdUp`
    padding: 60px 40px;
    margin: 0 auto;
    flex-direction: row;
    max-width: 1140px;
  `}

  ${media.lgUp`
    padding: 80px 0;
  `}
`;

export const StatsStyled = styled.div`
  margin-bottom: 40px;

  &:last-child {
    margin-bottom: 0;
  }

  ${media.mdUp`
    flex: 1 0 0;
    margin: 0 auto;
  `}
`;

export const ThumbsUpIconStyled = styled(ThumbsUp).attrs(({ theme }) => ({
  fill: theme.color.secondary,
  kind: 'hollow',
  width: '55px',
  height: '64px'
}))``;

export const GuranteeBadgeStyled = styled(GuaranteeBadge).attrs(({ theme }) => ({
  fill: theme.color.secondary,
  width: '55px',
  height: '64px',
  kind: 'hollow'
}))``;

export const DirectoryCheckListStyled = styled(DirectoryCheckList).attrs(({ theme }) => ({
  fill: theme.color.secondary,
  width: '55px',
  height: '64px',
  kind: 'hollow'
}))``;

export const StatsSubHeadingStyled = styled(H4)`
  color: ${({ theme }) => theme.color.tertiary};
  font-family: ${({ theme }) => theme.font.secondaryBold};
  margin: 24px 0 0;
  font-weight: 600;
`;

export const StatsDescriptionStyled = styled(Paragraph)`
  color: ${({ theme }) => theme.color.tertiaryDark};
  font-size: 16px;
  line-height: 1.5;
  margin: 8px 0 0;
`;
