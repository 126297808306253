import {
  memo, useState, useEffect, useCallback, useContext
} from 'react';

import { JobFormControllerContext } from 'lib/oneflare-job-form/utils/context';
import { publicRuntimeConfig } from 'lib/utils/Environment';

import {
  HeroStyled,
  TitleStyled,
  LabelStyled,
  AutoCompleteStyled,
  ButtonStyled,
  HeroContentStyled,
  InputWrapperStyled,
  ServiceDropDownStyled,
  DropDownStyled,
  LocationAutoCompleteStyled
} from './styled/HeroDropDownStyled';

const weddingCategories = [{
  label: 'Catering',
  value: 57
},
{
  label: 'Celebrant',
  value: 162
},
{
  label: 'DJ',
  value: 261
},
{
  label: 'Entertainer',
  value: 262
},
{
  label: 'Car Hire',
  value: 6
},
{
  label: 'Florist',
  value: 198
},
{
  label: 'Hair Stylist',
  value: 308
},
{
  label: 'Makeup Artist',
  value: 309
},
{
  label: 'Photographer',
  value: 8
},
{
  label: 'Planner',
  value: 160
},
{
  label: 'Videographer',
  value: 9
}];

const HeroDropDown = () => {
  const [locationOptions, setLocationOptions] = useState([]);
  const [selectedLocationInput, setSelectedLocationInput] = useState('');
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [lastSelectedLocation, setLastSelectedLocation] = useState(null);
  const [dropDownValue, setDropDownValue] = useState('');
  const { controller } = useContext(JobFormControllerContext);

  const handleChange = ({ target: { value } }) => {
    if (value && value.trim().length > 0) {
      setTimeout(async () => {
        const getlocationOptions = await fetch(
          `${publicRuntimeConfig.JOB_FORM_URL || ''}/api/autocomplete/v1/locations/search?query=${encodeURIComponent(
            value
          )}`
        ).then((response) => {
          if (!response.ok) throw Error(response.statusText);
          return response.json();
        });
        setLocationOptions(getlocationOptions);
      }, 250);
    }
    setSelectedLocationInput(value);
    setLastSelectedLocation(null);
  };

  // TODO: check redirectOnSuccess: true on handleJobFormLaunch
  
  const handleJobFormLaunch = useCallback(async () => {
    const locationId = lastSelectedLocation?.id;
    await controller?.open({
      categoryId: selectedCategoryId,
      ...(locationId && { locationId })
    });
  }, [controller, lastSelectedLocation, selectedCategoryId]);

  useEffect(() => {
    if (!selectedCategoryId || !lastSelectedLocation) return;
    handleJobFormLaunch();
  }, [selectedCategoryId, lastSelectedLocation, handleJobFormLaunch]);

  const handleSelectLocation = (option) => {
    const selectedLocationInput = locationOptions.find((item) => item.value === option.value);
    setSelectedLocationInput(option.value);
    setLastSelectedLocation(selectedLocationInput);
  };

  const handleFocus = (e) => {
    e.target.select();
  };

  const handleCategorySelect = (e) => {
    setSelectedCategoryId(e.value);
    setDropDownValue(e.value);
  };

  return (
    <HeroStyled id="site_form">
      <HeroContentStyled>
        <TitleStyled>Wedding planning made easy</TitleStyled>
        <InputWrapperStyled>
          <ServiceDropDownStyled>
            <LabelStyled htmlFor="dropdown-hero">
              What service do you need?
            </LabelStyled>
            <DropDownStyled
              id="dropdown-hero"
              value={dropDownValue}
              options={weddingCategories}
              handleChange={handleCategorySelect}
              isSelected={selectedCategoryId}
              placeholder="Please select a category"
            />
          </ServiceDropDownStyled>
          <LocationAutoCompleteStyled>
            <LabelStyled htmlFor="location-hero">
              Where do you need it?
            </LabelStyled>
            <AutoCompleteStyled
              type="text"
              id="location-hero"
              items={locationOptions.map(({ value }) => ({ name: value, value }))}
              onChange={handleChange}
              onFocus={handleFocus}
              onSelect={handleSelectLocation}
              placeholder="e.g. Sydney, 2000"
              value={selectedLocationInput}
            />
          </LocationAutoCompleteStyled>
          <ButtonStyled
            label="get free quotes"
            kind="secondary"
            onClick={handleJobFormLaunch}
          />
        </InputWrapperStyled>
      </HeroContentStyled>
    </HeroStyled>
  );
};

export default memo(HeroDropDown);
