import Anchor from '@oneflare/flarekit/lib/components/Anchor';
import { useState } from 'react';

import * as Styled from './styled/WeddingFooter';

enum LINK_TYPES {
  EMAIL = 'email',
  LINK = 'link',
}

const sections = [
  {
    heading: 'Ceremony',
    items: [
      {
        label: 'Celebrant',
        link: '/celebrant',
        key: 'celebrant',
        type: LINK_TYPES.LINK
      },
      {
        label: 'Wedding Car Hire',
        link: '/wedding-car-hire',
        key: 'wedding-car-hire',
        type: LINK_TYPES.LINK
      },
      {
        label: 'Florist',
        link: '/wedding-florist',
        key: 'florist',
        type: LINK_TYPES.LINK
      },
      {
        label: 'Photographer',
        link: '/wedding-photographer',
        key: 'photographer',
        type: LINK_TYPES.LINK
      },
      {
        label: 'Videographer',
        link: '/videographer',
        key: 'videographer',
        type: LINK_TYPES.LINK
      }
    ]
  },
  {
    heading: 'Reception',
    items: [
      {
        label: 'Caterer',
        link: '/catering',
        key: 'caterer',
        type: LINK_TYPES.LINK
      },
      {
        label: 'Entertainment',
        link: '/entertainers',
        key: 'entertainment',
        type: LINK_TYPES.LINK
      },
      {
        label: 'DJ',
        link: '/djs',
        key: 'dj',
        type: LINK_TYPES.LINK
      }
    ]
  },
  {
    heading: 'Beauty',
    items: [
      {
        label: 'Make-up Artist',
        link: '/wedding-makeup-artists',
        key: 'make-up-artist',
        type: LINK_TYPES.LINK
      },
      {
        label: 'Hair Stylist',
        link: '/wedding-hair-stylist',
        key: 'hair-stylist',
        type: LINK_TYPES.LINK
      }
    ]
  },
  {
    heading: 'Planning',
    items: [
      {
        label: 'Full Service Wedding Planner',
        link: '/wedding-planner',
        key: 'full-service-wedding-planner',
        type: LINK_TYPES.LINK
      },
      {
        label: 'Partial Wedding Planner',
        link: '/wedding-planner',
        key: 'partial-wedding-planner',
        type: LINK_TYPES.LINK
      },
      {
        label: 'On-the-day Co-ordinator',
        link: '/wedding-planner',
        key: 'on-the-day-coordinator',
        type: LINK_TYPES.LINK
      }
    ]
  },
  {
    heading: 'Contact Us',
    items: [
      {
        label: (
          <>
            Contact{' '}
            <Styled.FooterAnchor href="mailto:support@oneflare.com.au">
              support@oneflare.com.au
            </Styled.FooterAnchor><br/>
            for assistance
          </>
        ),
        key: 'contact-us',
        link: null,
        type: LINK_TYPES.EMAIL
      }
    ]
  }
];

type FooterLinksProps = {
  section: {
    heading: string;
    items: {
      label: string | JSX.Element;
      link?: string;
      key: string;
      type: LINK_TYPES;
    }[];
  };
};

const FooterLinks = ({ section }: FooterLinksProps) =>
  section.items.map((item) => {
    if (item.type === LINK_TYPES.LINK) {
      return (
        <Styled.ListItem key={item.key}>
          <Styled.FooterAnchor href={item.link}>
            {item.label}
          </Styled.FooterAnchor>
        </Styled.ListItem>
      );
    }
    return <Styled.ListItem key={item.key}>{item.label}</Styled.ListItem>;
  });

const FooterSection = () => {
  const [activeSection, setActiveSection] = useState(null);
  const handleAccordionToggle = (headingName) => {
    setActiveSection(activeSection === headingName ? false : headingName);
  };

  return (
    <Styled.FooterWrapper>
      <Styled.FooterHead>
        <Anchor href="/" aria-label="wedding.com.au logo">
          <Styled.FooterWeddingLogo />
        </Anchor>
      </Styled.FooterHead>
      <Styled.FooterBody>
        {sections.map((section) => (
          <section key={section.heading}>
            <Styled.FooterSubHeading
              onClick={() => handleAccordionToggle(section.heading)}
            >
              {section.heading}
              <Styled.AccordionChevron $activeSection={activeSection} $headerName={section.heading} />
            </Styled.FooterSubHeading>
            <Styled.List>
              <Styled.CollapsibleItems expanded={activeSection === section.heading}>
                <FooterLinks section={section} />
              </Styled.CollapsibleItems>
              <Styled.ListItemContainer>
                <FooterLinks section={section} />
              </Styled.ListItemContainer>
            </Styled.List>
          </section>
        ))}
      </Styled.FooterBody>
    </Styled.FooterWrapper>
  );
};

export default FooterSection;
